/* General styles */
*{margin:0;padding:0;box-sizing:border-box;font-family:'Poppins',sans-serif;font-weight:400;}
.title{font-family:'Baskervville',serif;}
img, video, iframe, picture{display:block;}
.section{position:relative;}
.content{position:relative;width:100%;max-width:1400px;margin:auto;}
.flex_content{display:flex;flex-wrap:wrap;}
.content_half{width:50%;}

/* Menu floating */
.floating_menu_flag.show{opacity:1;}
.secondi.active{transform:translateY(100%);}
.bebidas.active{transform:translateY(0);}

/* Navigation bar */
.navbar{position:absolute;top:2rem;left:0;width:100%;height:2.5rem;z-index:9;}
.navbar .content{display:flex;align-items:center;justify-content:center;padding-top:0;padding-bottom:0;height:100%;}
.navbar_logo, .navbar_logo img{height:100%;width:auto;}
.navbar_ops{display:flex;align-items:center;padding-top:1rem;width:40%;}
.navbar_ops.left{justify-content:flex-end;}
.navbar_ops.left .navbar_ops__el:nth-child(2), .navbar_ops.right .navbar_ops__el:nth-child(3){margin-right:0;}
.navbar_ops__el{text-decoration:none;margin-right:1rem;position:relative;}
.navbar_ops__el.black{color:#000;}
.navbar_ops__el.white{color:#fff;}
.navbar_ops__el.principal::after{
    content:'';
    position:absolute;
    bottom:-.2rem;
    left:0;
    width:100%;
    height:.2rem;
    background:linear-gradient(to right, #134704 33%, white 33% 66%, #aa0000 66% 100%);
}

/* Footer */
.footer{background-color:#124625;}
.footer .content{display:flex;}
.footer_des, .footer_map{width:50%;position:relative;}
.footer_map img{width:100%;height:auto;}
.footer_map a{position:absolute;top:0;left:0;width:100%;height:100%;}
.footer_bg{position:absolute;top:0;left:0;height:100%;width:auto;}
.footer_des__t1{color:#fff;margin-bottom:1.5rem;}
.footer_des__link{text-decoration:none;display:flex;align-items:center;color:#fff;margin-bottom:1rem;}
.footer_des__link img{width:1rem;margin-right:.5rem;}
.footer_des_social{position:relative;}
.footer_des_social img{width:4rem;}
.footer_des_social__link{position:absolute;top:0;left:0;width:2rem;height:100%;}
.footer_des_social__link:nth-child(3){left:2rem;}

/* Copyright */
.copy{background-color:#0b2e18;}
.copy .content{padding-top:1rem;padding-bottom:1rem;color:#fff;text-align:center;}

/* Responsive style */
@media (max-width: 599px){
    .content{padding:3rem 1rem;}

    .navbar .content{flex-direction:column;}
    .navbar_logo, .navbar_logo img{height:2rem;width:auto;}
    .navbar_ops.left{display:none;}
    .navbar_ops.right{width:100%;justify-content:center;}

    .footer .content{flex-wrap:wrap;}
    .footer_bg{height:50%;width:auto;}
    .footer_des, .footer_map{width:100%;}
    .footer_des{margin-bottom:2rem;}
    .footer_des__t1{font-size:2rem;letter-spacing:5px;}
    .footer_des__link{font-size:1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .content{padding:4rem 2rem;}

    .navbar_ops.left{display:none;}
    .navbar_ops.right{flex-grow:1;justify-content:flex-end;}

    .navbar_logo{margin:0 2rem;}
    .navbar_ops__el{font-size:1.1rem;}

    .footer_des__t1{font-size:2rem;letter-spacing:5px;}
    .footer_des__link{font-size:1rem;}
    .footer_map{padding-left:1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .content{padding:4rem 3rem;}

    .navbar_logo{margin:0 2rem;}
    .navbar_ops__el{font-size:1.1rem;}

    .footer_des__t1{font-size:2.2rem;letter-spacing:5px;}
    .footer_des__link{font-size:1.1rem;}
    .footer_map{padding-left:2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .content{padding:4rem;}

    .navbar_logo{margin:0 2rem;}
    .navbar_ops__el{font-size:1.2rem;}

    .footer_des__t1{font-size:2.5rem;letter-spacing:5px;}
    .footer_des__link{font-size:1.2rem;}
}

@media (min-width:1400px){
    .content{padding:4rem;}

    .navbar_logo{margin:0 3rem;}
    .navbar_ops__el{font-size:1.3rem;}

    .footer_des__t1{font-size:3rem;letter-spacing:5px;}
    .footer_des__link{font-size:1.2rem;}
    .footer_map img{max-width:500px;margin:auto;}
}