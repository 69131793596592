/* Header */
.header{position:relative;}
.header_title{position:absolute;bottom:0;left:54.2%;transform:translate(-50%);height:calc(100% - 7rem);}
.header_bg{width:100%;height:auto;}

/* Bienvenido */
.bienvenido_content{text-align:center;font-style:italic;}
.bienvenido__t1{color:#2c2c2c;}
.bienvenido__t2{color:#183b25;margin-bottom:1.5rem;}
.bienvenido__sep{margin:auto;width:5rem;}

/* Historia */
.historia{display:flex;align-items:center;flex-wrap:wrap;}
.historia_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:fill;z-index:-1;}
.historia_image, .historia_des{width:50%;}
.historia_des{color:#4a4a4a;}
.historia_des__t:last-child{margin-bottom:0;}

/* Platillos */
.platillos_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.platillos_logo{position:absolute;top:50%;transform:translateY(-50%);}
.platillos_cubiertos{position:absolute;right:0;top:50%;transform:translateY(-50%);width:calc(100% - 38rem);}
.platillos_des{width:50%;}
.platillos_des__title{color:#124625;font-weight:bold;font-style:italic;margin-bottom:1rem;}
.platillos_des__t1{color:#272727;text-align:justify;margin-bottom:1rem;}
.platillos_des__t2{color:#124625;text-align:center;font-style:italic;}

/* Menu */
.menu{background-color:#e7e5de;}
.menu_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:fill;}
.menu_content{display:flex;align-items:center;}
.menu_image{width:55%;}
.menu_des{width:45%;font-style:italic;}
.menu_des__t3{color:#376044;}
.menu_image img{width:100%;height:auto;}
.menu_des__btn{font-style:normal;display:block;margin:auto;width:fit-content;margin-top:2rem;border:1px solid #376044;color:#376044;text-decoration:none;padding:.5rem 2rem;}

/* Reservar */
.reservar_content{text-align:center;}
.reservar__button{width:fit-content;margin:auto;padding:1.5rem 4rem;border:2px solid #124625;position:relative;text-decoration:none;color:#124625;}
.reservar__button span{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);border:1px solid #124625;width:calc(100% - .5rem);height:calc(100% - .5rem);}

/* Responsive style */
@media (max-width: 599px){
    .header_title{transform:translate(-62%);height:calc(100% - 6rem);}

    .bienvenido__t1{font-size:1.25rem;letter-spacing:-2px;}
    .bienvenido__t2{font-size:2rem;line-height:2rem;letter-spacing:-1px;}

    .historia{flex-direction:column-reverse;}
    .historia_image, .historia_des{width:100%;}
    .historia_des{padding:3rem 2rem;}
    .historia_des__t{font-size:1rem;margin-bottom:1rem;}

    .platillos_content{padding:3rem 2rem!important;}
    .platillos_des{width:100%;font-size:1rem;}
    .platillos_des__title{font-size:2rem;line-height:2.5rem;text-align:center;}
    .platillos_cubiertos, .platillos_logo{display:none;}

    .menu_content{flex-direction:column-reverse;}
    .menu_des{width:100%;margin-bottom:2rem;text-align:center;}
    .menu_image{width:90%;}
    .menu_des{font-size:1.5rem;margin-left:-1rem;}
    .menu_des__btn{margin-top:1rem;}

    .reservar_content{padding-top:5rem!important;padding-bottom:5rem!important;}
    .reservar__button{font-size:1.3rem;letter-spacing:.2rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .bienvenido__t1{font-size:1.5rem;letter-spacing:-2px;}
    .bienvenido__t2{font-size:2.8rem;line-height:2.8rem;letter-spacing:-2px;}

    .historia{flex-direction:column-reverse;}
    .historia_image, .historia_des{width:100%;}
    .historia_des{padding:4rem;}
    .historia_des__t{font-size:1.2rem;margin-bottom:1rem;}

    .platillos_content{padding:4rem!important;}
    .platillos_des{width:100%;font-size:1.2rem;}
    .platillos_des__title{font-size:2.5rem;line-height:2.8rem;}
    .platillos_cubiertos, .platillos_logo{display:none;}

    .menu_content{flex-direction:column-reverse;}
    .menu_des{width:100%;margin-bottom:2rem;text-align:center;}
    .menu_image{width:25rem;}
    .menu_des{font-size:1.9rem;margin-left:-1rem;}
    .menu_des__btn{margin-top:1rem;}

    .reservar_content{padding-top:6rem!important;padding-bottom:6rem!important;}
    .reservar__button{font-size:1.8rem;letter-spacing:.2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .bienvenido__t1{font-size:1.7rem;letter-spacing:-2px;}
    .bienvenido__t2{font-size:3rem;line-height:3rem;letter-spacing:-2px;}

    .historia_des{padding:2rem;}
    .historia_des__t{font-size:1.2rem;margin-bottom:1rem;}

    .platillos_logo{left:calc(100% - 19rem);transform:translate(-50%,-50%);width:18rem;}
    .platillos_des{max-width:26rem;margin-left:1rem;font-size:1.2rem;}
    .platillos_des__title{font-size:2.5rem;line-height:2.8rem;}
    .platillos_cubiertos{width:calc(100% - 33rem);}

    .menu_image{width:43%;}
    .menu_des{width:calc(57% + 3rem);}
    .menu_des{font-size:1.9rem;margin-left:-1rem;}
    .menu_des__t2{margin-left:3rem;}
    .menu_des__t3{margin-left:5.5rem;}

    .reservar_content{padding-top:6rem!important;padding-bottom:6rem!important;}
    .reservar__button{font-size:2rem;letter-spacing:.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .bienvenido__t1{font-size:1.7rem;letter-spacing:-2px;}
    .bienvenido__t2{font-size:3rem;line-height:3rem;letter-spacing:-2px;}

    .historia_des{padding:4rem;}
    .historia_des__t{font-size:1.2rem;margin-bottom:1rem;}

    .platillos_logo{left:calc(100% - 19rem);transform:translate(-50%,-50%);width:22rem;}
    .platillos_des{max-width:26rem;margin-left:3rem;font-size:1.2rem;}
    .platillos_des__title{font-size:2.5rem;line-height:2.8rem;}

    .menu_des{font-size:1.9rem;margin-left:-1rem;}
    .menu_des__t2{margin-left:3rem;}
    .menu_des__t3{margin-left:5.5rem;}

    .reservar__button{font-size:2rem;letter-spacing:.2rem;}
}

@media (min-width:1400px){
    .header{max-height:90vh;}
    .header img{max-height:90vh;}
    .header_title{height:calc(100% - 10rem);}

    .bienvenido__t1{font-size:2rem;letter-spacing:-2px;}
    .bienvenido__t2{font-size:3.8rem;line-height:3.8rem;letter-spacing:-2px;}
    .bienvenido__sep{width:8rem;}

    .historia_image{width:40%;}
    .historia_des{width:60%;padding:4rem;}
    .historia_des__t{font-size:1.4rem;margin-bottom:1rem;max-width:42rem;}

    .platillos_content{padding-top:6rem;padding-bottom:6rem;}
    .platillos_logo{left:calc(55% + 20rem);transform:translate(-50%,-50%);width:25rem;}
    .platillos_des{max-width:32rem;font-size:1.4rem;}
    .platillos_des__title{font-size:2.5rem;line-height:2.8rem;}
    .platillos_cubiertos{width:55%;}

    .menu_des{font-size:2.45rem;margin-left:-2rem;}
    .menu_des__t2{margin-left:2.5rem;}
    .menu_des__t3{margin-left:5rem;}

    .reservar_content{padding-top:8rem;padding-bottom:8rem;}
    .reservar__button{font-size:3rem;letter-spacing:.3rem;}
}