/* Header */
.header_bg{width:100%;height:auto;}
.header_title{position:absolute;transform:translateX(-50%);color:#fff;}

/* Menu */
.menu_el{display:flex;flex-wrap:wrap;align-items:flex-start;}
.menu_el:nth-child(even){flex-direction:row-reverse;}
.menu_el_poster{width:38%;}
.menu_el_platillos{width:62%;}
.menu_el_platillos__img{width:10rem;margin:auto;margin-bottom:1rem;}
.menu_el_platillos__t1{text-align:center;margin-bottom:4rem;}
.menu_el_platillos__sep{width:100%;height:1px;background-color:#000;margin-bottom:2rem;}

/* Floating menu */
.floating_menu_con{position:fixed;bottom:0;left:0;width:100%;display:flex;flex-direction:column;opacity:0;transition:all .5s ease;}
.floating_menu{background-color:#183b25;padding:1rem;display:flex;justify-content:center;z-index:2;}
.floating_menu__el{text-decoration:none;color:#fff;margin-right:1rem;cursor:pointer;}
.floating_menu__el:nth-child(4){margin-right:0;}
.floating_sub_secondi,.floating_sub_bebidas{background-color:#183b25;padding:1rem;transition:all .5s ease;}
.floating_sub_secondi{transform:translateY(200%);}
.floating_sub_bebidas{transform:translateY(100%);}
.floating_sub_el{display:block;text-decoration:none;color:#fff;margin-bottom:.5rem;}
.floating_sub_el:last-child{margin-bottom:0;}

/* Platillo */
.menu_platillo{margin-bottom:2rem;padding-bottom:2rem;border-bottom:1px solid #000;}
.menu_platillo_title{display:flex;align-items:center;margin-bottom:1.5rem;}
.menu_el_platillos__title{margin-bottom:2rem;}
.menu_platillo__nombre span{margin-left:.5rem;}
.menu_platillo__precio{flex-grow:1;text-align:right;}
.menu_el__disclaimer{text-align:center;margin:auto;width:100%;max-width:650px;}

.menu_el_platillos_title{display:flex;align-items:flex-end;margin-bottom:2rem;padding-top:2rem;margin-top:2rem;border-top:1px solid #000;}
.menu_el_platillos_title_platillo{padding-top:0!important;;margin-top:0!important;;border-top:0!important;}
.menu_el_platillos_title__t1{flex-grow:1;}
.menu_el_platillos_title__des{text-align:center;width:25%;}
.menu_el_platillos_title__des span{display:block;}

.menu_bebida{display:flex;margin-bottom:1rem;}
.menu_bebida__nombre{width:50%;flex-grow:1;}
.menu_bebida__nombre span{margin-left:.5rem;}
.menu_bebida__precio{width:25%;text-align:center;}

/* Responsive style */
@media (max-width: 599px){
    .header_title{display:none;}

    .menu_el_poster{display:none;}
    .menu_el_platillos{width:100%;}

    .menu_con{padding:2rem 0;}
    .menu_el_platillos{padding:2rem 1rem;}
    .menu_el_platillos__t1{font-size:1.8rem;letter-spacing:2px;}

    .menu_platillo__nombre{font-size:1.1rem;padding-right:1rem;}
    .menu_el_platillos__title{font-size:1.5rem;}
    .menu_platillo__des{padding-right:2rem;}
    .menu_el__disclaimer{font-size:.9rem;padding:0 2rem;}

    .menu_el_platillos_title__t1{font-size:1.2rem;}
    .menu_el_platillos_title__des{font-size:.6rem;}

    .menu_bebida__nombre{font-size:1.1rem;}
    .menu_bebida__nombre span{font-size:.8rem;}
    .menu_bebida__precio{font-size:.9rem;}

    .floating_menu__el{font-size:1rem;}
    .floating_sub_el{font-size:1.1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .header_title{font-size:3rem;letter-spacing:5px;top:30%;left:46.5%;}

    .menu_el_poster{display:none;}
    .menu_el_platillos{width:100%;}

    .menu_con{padding:2rem 0;}
    .menu_el_platillos{padding:2rem;}
    .menu_el_platillos__t1{font-size:1.8rem;letter-spacing:2px;}

    .menu_platillo__nombre{font-size:1.2rem;}
    .menu_el_platillos__title{font-size:1.5rem;}
    .menu_platillo__des{padding-right:4rem;}

    .menu_el_platillos_title__t1{font-size:1.4rem;}
    .menu_el_platillos_title__des{font-size:.8rem;}

    .menu_bebida__nombre{font-size:1.2rem;}
    .menu_bebida__nombre span{font-size:.8rem;}
    .menu_bebida__precio{font-size:.9rem;}

    .floating_menu__el{font-size:1.2rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_sub_secondi, .floating_sub_bebidas{padding:1rem 2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .header_title{font-size:3.5rem;letter-spacing:5px;top:29.5%;left:47.5%;}

    .menu_con{padding:4rem 0;}
    .menu_el_platillos{padding:2rem 2rem;}
    .menu_el_platillos__t1{font-size:1.8rem;letter-spacing:2px;}

    .menu_platillo__nombre{font-size:1.2rem;}
    .menu_el_platillos__title{font-size:1.5rem;}
    .menu_platillo__des{padding-right:4rem;}
    .menu_el__disclaimer{margin-bottom:4rem;}

    .menu_el_platillos_title__t1{font-size:1.4rem;}
    .menu_el_platillos_title__des{font-size:.8rem;}

    .menu_bebida__nombre{font-size:1.2rem;}
    .menu_bebida__nombre span{font-size:.8rem;}

    .floating_menu__el{font-size:1.2rem;margin-right:1.5rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_sub_secondi, .floating_sub_bebidas{padding:1rem 3rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .header_title{font-size:4rem;letter-spacing:5px;top:29.5%;left:47.8%;}

    .menu_con{padding:4rem 0;}
    .menu_el_platillos{padding:2rem 3rem;}
    .menu_el_platillos__t1{font-size:1.8rem;letter-spacing:2px;}

    .menu_platillo__nombre{font-size:1.2rem;}
    .menu_el_platillos__title{font-size:1.5rem;}
    .menu_platillo__des{padding-right:4rem;}
    .menu_el__disclaimer{margin-bottom:4rem;}

    .menu_el_platillos_title__t1{font-size:1.4rem;}
    .menu_el_platillos_title__des{font-size:.8rem;}

    .menu_bebida__nombre{font-size:1.2rem;}
    .menu_bebida__nombre span{font-size:.8rem;}

    .floating_menu__el{font-size:1.2rem;margin-right:1.5rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_sub_secondi, .floating_sub_bebidas{padding:1rem 4rem;}
}

@media (min-width:1400px){
    .header_title{font-size:5rem;letter-spacing:8px;top:29%;left:48%;}

    .menu_con{padding:5rem 0;}
    .menu_el_platillos{padding:3rem 5rem;}
    .menu_el_platillos__t1{font-size:2.2rem;letter-spacing:5px;}

    .menu_platillo__nombre{font-size:1.4rem;}
    .menu_el_platillos__title{font-size:1.5rem;}
    .menu_platillo__des{padding-right:4rem;font-size:1.1rem;}
    .menu_platillo__precio{font-size:1.1rem;}
    .menu_el__disclaimer{margin-bottom:4rem;}

    .menu_el_platillos_title__t1{font-size:1.8rem;}
    .menu_el_platillos_title__des{font-size:.9rem;}

    .menu_bebida__nombre{font-size:1.2rem;}
    .menu_bebida__nombre span{font-size:.9rem;}

    .floating_menu__el{font-size:1.2rem;margin-right:1.5rem;}
    .floating_sub_el{font-size:1.2rem;}
    .floating_sub_secondi, .floating_sub_bebidas{padding:1rem 4rem;}
}